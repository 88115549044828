import "./index.scss";
import _ from 'lodash'
import { useEffect, useState } from "react";
// import useCopyToClipboard from "../usehooks";
import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";
import { linkWallet, NxWeb3 } from "../../libs/NxWeb3";
import { Link, useParams } from "react-router-dom";
import CountUp from "react-countup";
import CountDown from "../../components/countDown";
import { dateCount, DateCount } from "../../libs/utils";

const countryName = [
  {
      "id":0,
      "name":"Spain"
  },
  {
      "id":1,
      "name":"Argentina"
  },
  {
      "id":2,
      "name":"Brazil"
  },
  {
      "id":3,
      "name":"France"
  },
  {
      "id":4,
      "name":"Australia"
  },
  {
      "id":5,
      "name":"Germany"
  },
  {
      "id":6,
      "name":"England"
  },
  {
      "id":7,
      "name":"Netherlands"
  },
  {
      "id":8,
      "name":"Portugal"
  },
  {
      "id":9,
      "name":"Belgium"
  },
  {
      "id":10,
      "name":"Croatia"
  },
  {
      "id":11,
      "name":"Switzerland"
  },
  {
      "id":12,
      "name":"Denmark"
  },
  {
      "id":13,
      "name":"Serbia"
  },
  {
      "id":14,
      "name":"Poland"
  },
  {
      "id":15,
      "name":"South Korea"
  },
  {
      "id":16,
      "name":"Japan"
  },
  {
      "id":17,
      "name":"Uruguay"
  },
  {
      "id":18,
      "name":"Ecuador"
  },
  {
      "id":19,
      "name":"Canada"
  },
  {
      "id":20,
      "name":"United States"
  },
  {
      "id":21,
      "name":"Mexico"
  },
  {
      "id":22,
      "name":"Cameroon"
  },
  {
      "id":23,
      "name":"Morocco"
  },
  {
      "id":24,
      "name":"Ghana"
  },
  {
      "id":25,
      "name":"Senegal"
  },
  {
      "id":26,
      "name":"Qatar"
  },
  {
      "id":27,
      "name":"Iran"
  },
  {
      "id":28,
      "name":"Saudi Arabia"
  },
  {
      "id":29,
      "name":"Wales"
  },
  {
      "id":30,
      "name":"Tunisia"
  },
  {
      "id":31,
      "name":"Costa Rica"
  }
]

const qaList = [
  {
    q: 'Who is The 12th Man?',
    a: 'The 12th Man usually refers to the fans of football tournaments. To kick off the World Cup feast and let sports enthusiasts battle the peak, we are launching The 12th Man NFT, a decentralized project BUIDL by fans for fans.'
  },
  {
    q:'What is The 12th Man?',
    a: 'The 12th Man is an NFT project for fans of the FIFA World Cup Qatar 2022. Each The 12th Man NFT is priced at 0.05 ETH. Every NFT mint is 100% generated on-chain in the smart contract and lives on as an immutable ERC-1155 token.'
  },
  {
    q: 'How to earn?',
    a: "As making predictions of the final winner, you can mint NFTs by picking among 32 participating countries. You can HODL more than one NFT to increase your cut of the prize pool. You can also trade your NFTs based on up-to-date tournaments. The total prize pool will be shared with each player in the champion's team after the World Cup ends."
  },
  {
    q: 'How do we allocate NFT sales?',
    a: '80% into the Prize Pool; 15% into the Referral Rewards; 5% into the 12th Man team.'
  },
  {
    q: 'What is the Referral Rewards?',
    a: 'Once your MetaMask is connected, you can invite friends to mint The 12th Man NFT using your share link displayed on the website. For each NFT minted through the Invite Link, you will directly receive 15% of that NFT sale as Referral Rewards. You will also be rewarded The 12th Man NFTs airdrop, join our Discord to find out more.'
  }
]



const Home = (): JSX.Element => {
  // @ts-ignore
  // const [copyValue, copyLink] = useCopyToClipboard()
  const [isConnected, setIsConnected] = useState(true);
  const [address, setAddress] = useState('');
  const [teamCountsArr, setTeamCountsArr] = useState<any>([])
  const [InviteBalance, setInviteBalance] = useState(0)
  const [WinnerBalance, setWinnerBalance] = useState(0)
  const routeParams = useParams();
  const hideAddress = (str:string)=>{

    return str.substr(0,5)+"****"+str.substr(str.length-4);  
  }
  const coppyUrl=(url:string)=>{
    copy(url);
    toast.success('Copied!')
  };
  useEffect(()=>{
    getWinnerBalanceFun()
    getTeamCountsFun()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 总奖池数量
  const getWinnerBalanceFun = async ()=>{
     const winnerBalance = await NxWeb3.instance.getWinnerBalance()
     setWinnerBalance(winnerBalance)
  }

  // 所有球队购买情况
  const getTeamCountsFun = async () => {
    const teamCounts = await NxWeb3.instance.getTeamCounts()
    const data:any = [];
    teamCounts.forEach((el: any,index: any) => {
      const name =  getTeamName(index)
      const params = {
        count: Number(el),
        name,
        ord: index
      }
      data.push(params)
    });
    const newArr:any = _.orderBy(data, ['count'],['desc']);
    console.log('%c🀁 newArr', 'color: #bfffc8; font-size: 20px;', newArr);
    console.log('%c🀀 teamCounts', 'color: #731d6d; font-size: 20px;', teamCounts);

    setTeamCountsArr(newArr)
  }

  const connectMetaMask = async () => {
    const result = await linkWallet(() => {
      setAddress(NxWeb3.instance.accountAddress);
    })
    setIsConnected(!result);
    if (NxWeb3.instance.isInited) {
      setAddress(NxWeb3.instance.accountAddress);
    }
    
    // 某钱包邀请奖励
    const inviteBalance = await NxWeb3.instance.getInviteBalance(NxWeb3.instance.accountAddress)
    console.log('%c🀁 inviteBalance', 'color: #e57373; font-size: 20px;', inviteBalance);
    setInviteBalance(inviteBalance)
  }

  const getTeamName = (ord: any) =>{
    const item = _.find(countryName, ['id', ord]);
    return item?.name
  }

  const WithdrawInvite = async ()=>{
    if(InviteBalance !== 0){
      await NxWeb3.instance.getWithdraw()
    } else {
      toast.warn('No funds yet. Invite now to get rewards.')
    }
  }

  
 
  return (
    <>
      <div className="home">
        <div className="container">
          <div className="header">
            <div className="logo">
              <img src={require('../../assets/img/worldcup.png').default} alt="" width={'100%'}/>
            </div>
            <div className="text">
              <div className="name">Prize Pool</div>
              <div className="prizepool"> <CountUp  decimals={2}
  decimal="." end={WinnerBalance} duration={1}/> ETH</div>
            </div>
          </div>
          <div className="main">
            <div className="left">
              {/* <div className="selectTime">
                <div className="tit">TIME</div>
                <div className="item active">5m</div>
                <div className="item">1h</div>
                <div className="item">7d</div>
                <div className="item">all</div>
              </div> */}
              <div className="LEADERBOARD">
                <h6 className="title">Leaderboard</h6>
                <div className="LEADERBOARDList">
                  {
                    teamCountsArr.length>0
                    ? <>
                        <div className="BoxList">
                          <div className="item header">
                            <div className="ord title">RANK</div>
                            <div className="spec"></div>
                            <div className="count title">SALES</div>
                          </div>
                          {
                          _.chunk(teamCountsArr,teamCountsArr.length/2)[0].map((el:any, index) => (
                            <div className="item" key={index}>
                              <div className="ord">{index+1}.</div>
                              <div className="name">
                                <img src={require(`../../assets/icon/${el.ord}.png`).default} alt="" />
                                {el.name}
                                
                              </div>
                              <div className="count"><CountUp end={el.count} duration={3}/></div>
                            </div>
                          )
                          )}
                        </div>
                        <div className="BoxList">
                          <div className="item hidePhone">
                            <div className="ord title">RANK</div>
                            <div className="spec"></div>
                            <div className="count title">SALES</div>
                          </div>
                          {_.chunk(teamCountsArr,teamCountsArr.length/2)[1].map((el:any, index) =>  (
                              <div className="item" key={index}>
                                <div className="ord">{index+teamCountsArr.length/2+1}.</div>
                                <div className="name">
                                  <img src={require(`../../assets/icon/${el.ord}.png`).default} alt="" />
                                  {el.name}
                                </div>
                                <div className="count"><CountUp end={el.count} duration={3}/></div>
                              </div>
                            )
                          )}
                        </div>
                      </>
                    : <>
                        <div className="BoxList">
                          <div className="item hidePhone">
                            <div className="ord title">RANK</div>
                            <div className="spec"></div>
                            <div className="count title">SALES</div>
                          </div>
                          {
                          _.chunk(countryName,countryName.length/2)[0].map((el, index) => (
                            <div className="item" key={index}>
                              <div className="ord">{index+1}.</div>
                              <div className="name">
                                <img src={require(`../../assets/icon/${el.id}.png`).default} alt="" />
                                {el.name}
                                
                              </div>
                              <div className="count"><CountUp end={0} duration={3}/></div>
                            </div>
                          )
                          )}
                        </div>
                        <div className="BoxList">
                          <div className="item">
                            <div className="ord title">RANK</div>
                            <div className="spec"></div>
                            <div className="count title">SALES</div>
                          </div>
                          {_.chunk(countryName,countryName.length/2)[1].map((el, index) =>  (
                              <div className="item" key={index}>
                                <div className="ord">{index+countryName.length/2+1}.</div>
                                <div className="name">
                                  <img src={require(`../../assets/icon/${el.id}.png`).default} alt="" />
                                  {el.name}
                                </div>
                                <div className="count"><CountUp end={0} duration={3}/></div>
                              </div>
                            )
                          )}
                        </div>
                      </>
                  }
                  
                </div>
              </div>
            </div>
            <div className="right">
              <div className="StartTime">
                <div className="timeBox">
                  <h6 className="tit">Ends in</h6>
                  <div className="time">
                    <CountDown propsDate={'1669046400000'} />
                  </div>
                </div>
                <div className="mint">
                  <Link className="btn" to={
                    routeParams.inviteAddress === undefined
                    ?"/mint"
                    :routeParams.inviteAddress === address
                      ?"/mint"
                      :`/mint/${routeParams.inviteAddress}`}>MINT</Link>
                </div>
              </div>
              {
                address
                ? <div className="share">
                    <div className="address">
                      <span>{hideAddress(address)}</span>
                      <i className="icon"><img src={require('../../assets/img/quit.png').default} width="100%" alt="" /></i>
                    </div>
                    <div className="link">
                      <h6 className="tit">Invite Link</h6>
                      <div className="copyLinkBox">
                        <div className="link">{`${window.location.href.split('://')[0]}://${window.location.host}/${address}`}</div>
                        <div className="copy" onClick={()=>coppyUrl(`${window.location.href.split('://')[0]}://${window.location.host}/${address}`)} >
                          <img src={require('../../assets/img/copy.png').default} alt="" width={"100%"}/>
                        </div>
                      </div>
                    </div>
                    <div className="reward">
                      <div className="left">
                        <h6 className="tit">My Rewards</h6>
                        <div className="balance">
                          {InviteBalance === 0?0:Number(InviteBalance).toFixed(2)} <span>ETH</span>
                        </div>
                      </div>
                      <div className="right">
                        <button className="btn" onClick={WithdrawInvite}>WITHDRAW</button>
                      </div>
                    </div>
                    <div className="tip">
                    Tips：Mint through your personal link，you will get 15% promotion rewards
                    </div>
                  </div>
                  :''
              }
              
            </div>
          </div>
          <FAQ></FAQ>
        </div>
      </div>
      {
          isConnected
            ?
            <>
              <div className="modelConnectMask">
                <div className="model-content">
                  <div className="connectBox">
                    <h1 className="title">Connect Wallet</h1>
                    <div className="connectBtn" onClick={()=>{
                      connectMetaMask()
                    }}>
                      <img src={require('../../assets/img/metamask.svg').default} alt="" />
                      MetaMask
                    </div>
                  </div>
                </div>
              </div>
            </>
            : <></>
        }
    </>
  );
}


const FAQ = (): JSX.Element => {
  const [collapseActiveIndex, setCollapseActiveIndex] = useState<Number>()

  return (
    <div className="qa">
    <h1 className="title">FAQ</h1>
    <div className="collapse">
      {
        qaList.map((el, index) => (
          <div key={index} className={['collapse-item', collapseActiveIndex===index?'active':''].join(' ')} onClick={()=>{
            if(collapseActiveIndex === index){
              setCollapseActiveIndex(-1)
            } else {
              setCollapseActiveIndex(index)
            }
            
          }}>
            <div className="collapse-item-header">
              <div className="collapse-item-header-icon">
                <svg className="open" preserveAspectRatio="xMinYMin meet" viewBox="0 0 1024 1024" version="1.1">
                  <path d="M587.229378 437.990403 580.722174 437.990403 76.975139 437.990403 76.975139 581.918419 580.722174 581.918419 587.229378 581.918419 940.542216 581.918419 940.542216 437.990403Z" p-id="2542"></path>
                </svg>
                <svg className="close" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" ><path d="M939.939489 459.072557 562.339502 459.072557 562.339502 83.519182 462.055494 83.519182 462.055494 459.072557 84.455507 459.072557 84.455507 559.356564 462.055494 559.356564 462.055494 939.003164 562.339502 939.003164 562.339502 559.356564 939.939489 559.356564Z" p-id="3443"></path></svg>
              </div>
              {el.q}
            </div>
            <div className="collapse-item-content">
              <div className="collapse-item-content-box">
                {el.a}
              </div>
            </div>
          </div>
        ))
      }
      
      
    </div>
  </div>
  )
}


const CountDownComp = (): JSX.Element => {
  const [dateCountNumbers, setDateCountNumbers] = useState<DateCount>();
  let timer: NodeJS.Timeout | null = null;
  const [nowTime, setNowTime] = useState<any>()
  const preMIntBeginTime = 1668261600000 // premint 开始时间 2022-11-12 22:00:00
  const preMIntEndTime = 1668279600000 // premint 结束时间 2022-11-13 3:00:00
  // const preMIntBeginTime = 1667642400000 // premint倒计时 开始时间 2022-11-05 18:00:00
  // const preMIntEndTime = 1667644200000 // premint倒计时 结束时间 2022-11-05 18:30:00


  const publicMIntBeginTime = 1668348000000 // publicmint 开始时间 2022-11-13 22:00:00

  const publicMIntEndTime = 1668873600000 // publicmint倒计时 结束时间 2022-11-20 00:00:00
  // const publicMIntBeginTime = 1667646000000 // publicmint 开始时间 2022-11-05 19:00:00

  // const publicMIntEndTime = 1667647800000 // publicmint倒计时 结束时间 2022-11-05 19:30:00

  useEffect(() => {
    countDown();
    return () => {
      if (timer != null) {
        clearInterval(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countDown = () => {
    timer = setInterval(() => {
      const time:any = new Date(new Date().toUTCString());
      let dates;
      if(time < preMIntEndTime){
        console.log(1, preMIntBeginTime)
        dates = dateCount(preMIntBeginTime);

      } else {
        console.log(2, publicMIntBeginTime)
        dates = dateCount(publicMIntBeginTime);
      }
      setDateCountNumbers(dates)
      setNowTime( Math.round(time))
    }, 1000);
  }
  return (
    <>
      <div className="CountDown">
        <div className="banner">
          <div className="countboxBox">
            {
              dateCountNumbers
              ? nowTime > publicMIntBeginTime && nowTime < publicMIntEndTime
                ?<div className="title">Public Sale</div>
                :<div className="title">Whitelist Sale</div>
              :<></>
            }
            {
              dateCountNumbers
              ?
                nowTime > preMIntBeginTime && nowTime < preMIntEndTime
                ? <a className="toMint" href="/premint">MINT</a>
                : <>
                    <div className="mint-count-down">
                      <div className="mint-count-down-item">
                        <div className="mint-count-down-item-wrap">
                          <div className="mint-count-down-item-wrap-container">
                            <div className="mint-count-down-item-wrap-container-blur"></div>
                            <div className="mint-count-down-item-wrap-container-text">
                              {dateCountNumbers.hour + dateCountNumbers.day *24<10?`0${dateCountNumbers.hour + dateCountNumbers.day *24}`:dateCountNumbers.hour + dateCountNumbers.day *24}
                            </div>
                          </div>
                        
                        </div>
                      </div>
                      <div className="mint-count-down-doll">:</div>
                      <div className="mint-count-down-item">
                        <div className="mint-count-down-item-wrap">
                          <div className="mint-count-down-item-wrap-container">
                            <div className="mint-count-down-item-wrap-container-blur"></div>
                            <div className="mint-count-down-item-wrap-container-text">
                              {dateCountNumbers.minute<10?`0${dateCountNumbers.minute}`:dateCountNumbers.minute}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mint-count-down-doll">:</div>
                      <div className="mint-count-down-item">
                        <div className="mint-count-down-item-wrap">
                          <div className="mint-count-down-item-wrap-container">
                            <div className="mint-count-down-item-wrap-container-blur"></div>
                            <div className="mint-count-down-item-wrap-container-text">
                              {dateCountNumbers.second<10?`0${dateCountNumbers.second}`:dateCountNumbers.second}
                            </div>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  </>
              :<></>
            }
            
          </div>
          
        </div>
        <div className="container">
          <FAQ></FAQ>
        </div>
      </div>
    </>
  )

}



const HomeComp = (): JSX.Element => {
  const [newTime, setNewTime] = useState<any>()
  let timer: NodeJS.Timeout | null = null;

  let publicMintTime =  1668348000000 // publicmint 开始时间 2022-11-13 22:00:00
  // let publicMintTime =  1667646000000 // publicmint 开始时间 2022-11-05 19:00:00


  
  useEffect(()=>{
   
    countDown();
    return () => {
      const time:any = new Date(new Date().toUTCString())
      if(time > publicMintTime){
        if (timer != null) {
          clearInterval(timer)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const countDown = () => {
    timer = setInterval(() => {
      const time:any = new Date(new Date().toUTCString())
      setNewTime( Math.round(time))
    }, 1000);
  }
  return(
    <>
      <div className="app">
        <div className="nav">
          <a href="https://discord.gg/yECvN4d8gr" className="item" target={'_blank'} rel="noreferrer">
            <img src={require('../../assets/img/discord.png').default} alt="" width={'100%'}/>
          </a>
          <a href="https://twitter.com/the12thMan_NFT" className="item" target={'_blank'} rel="noreferrer">
            <img src={require('../../assets/img/teitter.png').default} alt="" width={'100%'}/>
          </a>
        </div>
        {
        newTime > publicMintTime
          ? <><Home/></>
          : <><CountDownComp/></>
        }
      </div>
     
    </>
  )
}



export default HomeComp;
