export const isMobile = (): boolean => {
  let isMobile: boolean = false;

  const _isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  if (_isMobile) {
    isMobile = true;
  }

  return isMobile;
}

