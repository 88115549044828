import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import { linkWallet, NxWeb3 } from '../../libs/NxWeb3';
import './index.scss'

const countryName = [
  {
      "id":0,
      "name":"Spain"
  },
  {
      "id":1,
      "name":"Argentina"
  },
  {
      "id":2,
      "name":"Brazil"
  },
  {
      "id":3,
      "name":"France"
  },
  {
      "id":4,
      "name":"Australia"
  },
  {
      "id":5,
      "name":"Germany"
  },
  {
      "id":6,
      "name":"England"
  },
  {
      "id":7,
      "name":"Netherlands"
  },
  {
      "id":8,
      "name":"Portugal"
  },
  {
      "id":9,
      "name":"Belgium"
  },
  {
      "id":10,
      "name":"Croatia"
  },
  {
      "id":11,
      "name":"Switzerland"
  },
  {
      "id":12,
      "name":"Denmark"
  },
  {
      "id":13,
      "name":"Serbia"
  },
  {
      "id":14,
      "name":"Poland"
  },
  {
      "id":15,
      "name":"South Korea"
  },
  {
      "id":16,
      "name":"Japan"
  },
  {
      "id":17,
      "name":"Uruguay"
  },
  {
      "id":18,
      "name":"Ecuador"
  },
  {
      "id":19,
      "name":"Canada"
  },
  {
      "id":20,
      "name":"United States"
  },
  {
      "id":21,
      "name":"Mexico"
  },
  {
      "id":22,
      "name":"Cameroon"
  },
  {
      "id":23,
      "name":"Morocco"
  },
  {
      "id":24,
      "name":"Ghana"
  },
  {
      "id":25,
      "name":"Senegal"
  },
  {
      "id":26,
      "name":"Qatar"
  },
  {
      "id":27,
      "name":"Iran"
  },
  {
      "id":28,
      "name":"Saudi Arabia"
  },
  {
      "id":29,
      "name":"Wales"
  },
  {
      "id":30,
      "name":"Tunisia"
  },
  {
      "id":31,
      "name":"Costa Rica"
  }
]



const PreMint = (): JSX.Element => {
  const [address, setAddress] = useState('');
  const [defauleSelect, setDefauleSelect] = useState(countryName[0]);
  const [Quantity, setQuantity] = useState(3)
  const [WinnerBalance, setWinnerBalance] = useState(0)
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const maxQuantity = 10;
  const hideAddress = (str:string)=>{

    return str.substr(0,5)+"****"+str.substr(str.length-4);  
  }

  const connectMetaMask = async () => {
    const result = await linkWallet(() => {
      setAddress(NxWeb3.instance.accountAddress);
    })

    setIsConnected(result);
    if (NxWeb3.instance.isInited) {
      setAddress(NxWeb3.instance.accountAddress);
    }
  }
  useEffect(()=>{
    connectMetaMask()

    getWinnerBalanceFun()
  },[])

  // 总奖池数量
  const getWinnerBalanceFun = async ()=>{
     const winnerBalance = await NxWeb3.instance.getWinnerBalance()
     setWinnerBalance(winnerBalance)
  }

  const mintFun = async ()=>{
    if (!address || !isConnected) {
      // showToast('Plase connect wallet.', 0);
      connectMetaMask()
      return;
    }
    setIsLoading(true);
    const result = await NxWeb3.instance.preMint(defauleSelect.id, Quantity);
    if (result === true) {
      toast.success('Successed.');
    } else {
      toast.error(result.message);
    }
    setIsLoading(false);
  }


  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="mintCom">
        <div className="container">
          <div className="header">
            <div className="logo">
              <img src={require('../../assets/img/worldcup.png').default} alt="" width={'100%'}/>
            </div>
            <div className="text">
              <div className="name">Prize Pool</div>
              <div className="prizepool"><CountUp  decimals={2}
  decimal="." end={WinnerBalance} duration={1}/> ETH</div>
            </div>
          </div>
          <div className="mintBox">
            <div className="address">
              <span>{hideAddress(address)}</span>
              <i className="icon"><img src={require('../../assets/img/quit.png').default} width="100%" alt="" /></i>
            </div>
            <div className="from">
              <div className="from-item">
                <div className='lable'>TEAM</div>
                <div className="dropdown">
                  <button onClick={handleOpen}> 
                    <img src={require(`../../assets/icon/${defauleSelect.id}.png`).default} alt="" />
                    <div className="name">{defauleSelect.name}</div></button>
                  {open ? (
                    <ul className="menu">
                      {
                        countryName.map((el, index) => (
                          <li className="item" key={index} onClick={()=>{
                            setDefauleSelect(el)
                            handleOpen()
                          }}>
                             <img src={require(`../../assets/icon/${el.id}.png`).default} alt="" />
                            <div className="name">{el.name}</div>
                          </li>
                        ))
                      }
                    </ul>
                  ) : null}
                </div>
              </div>
              <div className="from-item">
                <div className="count">
                  <div className="inputGroup">
                    <button onClick={() => { if (Quantity > 1) { setQuantity(Quantity - 1) } }}>-</button>
                    <input type="text" value={Quantity} />
                    <button onClick={() => { if (Quantity < maxQuantity) { setQuantity(Quantity + 1) } }}>+</button>
                  </div>
                </div>
                <div className="tip">
                {/* Sold out 1:45:00 minutes to continue mint */}
                </div>
              </div>
              <div className="from-btn">
                <button className="mintBtn" onClick={mintFun}>
                  {address ===''?'Connect':isLoading?<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>:'Mint'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreMint;