import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import HomeComp from './pages/Home';
import Mint from './pages/mint';
import PreMint from './pages/preMint';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeComp />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/premint" element={<PreMint />} />
        <Route path="/:inviteAddress" element={<HomeComp />} />
        <Route path="/mint/:inviteAddress" element={<Mint />} />
      </Routes>
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
  );
}

export default App;
